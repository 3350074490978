import * as React from 'react'
import { Link } from 'gatsby'

import Tags from './tags'
import thumbnail from  '../images/thumbnail.png'
import processTags from '../services/processTags'
import Favebutton from "./faveButton"
import Rating from "./rating"

const ArticleSlug = ( { post } ) => {

    let keywords = processTags.process(post.frontmatter.keywords)
    let image = post.frontmatter.hero_image || thumbnail
    let alt = post.frontmatter.hero_image_alt || 'thumbnail'
    let rating = post.frontmatter.starRating

    if (image) image = image.replace('http://', 'https://')

    return (
        <article key={post.id} id={post.id} className='mb-8 bg-white rounded border border-gray-300 border-x-0 lg:border-x flex flex-col md:flex-row'>
            <div className='w-full md:w-64 lg:w-96'>
                <figure className='md:pl-4 md:py-4 md:min-w-[240px] lg:w-96 hidden md:block'>
                    <Link to={`/archive/${post.frontmatter.slug}/`}>
                        <img src={image} alt={alt} className='w-full' loading='lazy' />
                    </Link>
                </figure>
                {
                    rating && <Rating rating={rating}></Rating>
                }
            </div>
            <div className='px-2 md:px-4 pt-4 grow flex flex-col justify-between overflow-x-hidden'>
                <h3 className='mb-2 text-lg md:text-2xl'>
                    <Link to={`/archive/${post.frontmatter.slug}/`}>
                        {post.frontmatter.title}
                    </Link>
                </h3>
                <div className='text-base md:text-xl max-w-prose mb-4'>
                    <p>
                        {post.frontmatter.description}
                    </p>
                </div>
                <div className='hidden md:block'>
                    <Tags tags={keywords} nowrap={true}></Tags>
                </div>
                <div className='flex items-center justify-between w-100 mb-2'>
                    <div>
                        <p className='text-base md:text-lg font-mono'>{post.frontmatter.date} - {post.timeToRead} min read</p>
                    </div>
                    <Favebutton post={post} size={'36px'}></Favebutton>
                </div>
            </div> 
        </article>
    )
}

export default ArticleSlug