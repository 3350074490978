import React from 'react'
import { graphql, navigate } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import ArticleSlug from "../components/articleSlug"
import Pagination from "../components/pagination"
import Search from "../components/search"
import Tags from '../components/tags'
import ShareButtonsWrapper from "../components/shareButtonsWrapper"

import processTags from '../services/processTags'

import {
  pagetitle,
  pagecontent
} from '../components/layout.module.css'

const Archive = ({ data, location }) => {

  let 
  {description, twitter, title:sitetitle, siteUrl} = data.site.siteMetadata,
  { search } = location,
  query = new URLSearchParams(search),
  tag = query.get('tag'),
  pageKeywords,
  pageTitles,
  allTags = '',
  limit = 12,
  skip = 0,
  page = query.get('p') || 1,
  nodes = data.allMarkdownRemark.nodes,
  totalPages,
  totalNodes = nodes?.length,
  title = 'Archive',
  seoTitle,
  image,
  href = `${siteUrl}${location.pathname}${location.search}`

  for (let i = 0; i < nodes.length; i++) {
    allTags = `${allTags}, ${nodes[i].frontmatter.keywords}`
  }

  let keywords = processTags.process(allTags)

  const filterByTag = (node) => {
    if (node.frontmatter.keywords && node.frontmatter.keywords.toLowerCase().indexOf(tag.toLowerCase()) !== -1) return true
  }

  if (tag) {
    nodes = nodes.filter(filterByTag)
    totalNodes = nodes?.length
  }

  totalPages = Math.ceil(nodes.length / limit)
  if (page > totalPages) navigate('/archive/')

  if (tag) {
    description = `${nodes.length} "${tag}" post${ nodes.length !== 1 ? 's': ''} found in ${title}`
    seoTitle = `"${tag}" - ${nodes.length} post${ nodes.length !== 1 ? 's': ''}`
    title = `"${tag}"`
  }

  const getPostsTags = (posts) => {
    let arr = [tag]
    for (let i = 0; i < posts.length; i++) {
      arr = `${arr}, ${posts[i].frontmatter.keywords}`
    }
    pageKeywords = processTags.process(arr)
  }

  const getPostsTitles = (posts) => {
    let arr = []
    for (let i = 0; i < posts.length; i++) {
      let title = posts[i].frontmatter.title
      if (title.indexOf(' review ') !== -1) arr.push(title.split(' review')[0])
    }
    pageTitles = [...new Set(arr)]
    pageKeywords = [pageTitles, ...pageKeywords]
  }

  if (Number(page) > 1) skip = limit * (Number(page) -1)
  nodes = nodes.slice(skip, limit + skip)
  getPostsTags(nodes)
  getPostsTitles(nodes)

  image = nodes[0].frontmatter.hero_image

  return (
    <Layout>
      <Seo title={seoTitle || title} keywords={pageKeywords} description={description} image={image} />
      <div className={pagecontent}>
        <h1 className={pagetitle}>{title}</h1>
        <div className="mx-auto max-w-full mb-8">
          <div className='flex md:justify-center items-center mb-8 pb-0 pl-2 md:pl-0 pr-8'>
            <Search tags={keywords} tag={tag}></Search>
          </div>
        </div>
        <div className="mx-auto max-w-full mb-8">
        {
          nodes
          .map((post) => (
            tag && post.frontmatter.keywords && post.frontmatter.keywords.toLowerCase().indexOf(tag.toLowerCase()) === -1 ? null :
            <ArticleSlug post={post} key={post.id}></ArticleSlug> 
          ))
        }
        </div>
        {<Pagination page={page} totalPages={totalPages} tag={tag}></Pagination>}
        <details className='mb-8'>
          <summary className="text-center text-2xl mb-4 cursor-pointer text-primary">Filter posts by tag</summary>
          <Tags tags={keywords}></Tags>
        </details>
      </div>
      <ShareButtonsWrapper title={`${sitetitle}: ${title} - ${totalNodes} posts`} url={encodeURI(href)} twitterHandle={twitter} tags={pageTitles}></ShareButtonsWrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
        twitter
        title
        siteUrl
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date(formatString: "DD MMM YY")
          description
          title
          slug
          keywords
          hero_image
          hero_image_alt
          starRating
        }
        id
        timeToRead
      }
      totalCount
    }
  }
`

export default Archive