import React, { useEffect, useRef, useState } from 'react';
import {navigate} from 'gatsby'; 
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaSearch } from "@react-icons/all-files/fa/FaSearch";
import { FaPlay } from "@react-icons/all-files/fa/FaPlay";

import {
    inputSearch
  } from './forms.module.css'

const Search = ({tags, tag}) => {

    const [q, setQ] = useState(tag);

    const inputElement = useRef()
    const searchBtn = useRef()
    const searchQuery = useRef()
    const size = '30px'

    useEffect(() => {
        inputElement.current.value = searchQuery.current = tag
        setQ(tag);
    },[tag]) 

    const handleInputChange = event => {
        setQ(event.target.value);
        return (
            inputElement.current.value = tag = searchQuery.current = event.target.value
        )
    }

    const clearSearchInput = () => {
        setQ('');
        inputElement.current.value = tag = ''
        inputElement.current.focus()
        navigate('/archive/')
    }

    const filterPosts = () => {
        let query = inputElement.current.value
        setQ(query);
        shareEvent('filter', query)
        navigate(`/archive?tag=${encodeURIComponent(query)}`)
    }

    const shareEvent = (category, title) => {
        trackCustomEvent({
          category: category,
          action: "Search",
          label: title,
        })
    }

    return (
        <div className='relative'>
            <figure className='absolute opacity-50 left-2 top-3'>
                <FaSearch size={size} title="Search" />
            </figure> 
            <input
                type="text"
                name="search"
                aria-label="Search"
                placeholder="Search by tag"
                id='searchInput'
                autoComplete="off"
                onChange={handleInputChange}
                list='tagsdata'
                ref={inputElement}
                className={inputSearch}
            />
            {
                <button className='absolute right-2 top-3 disabled:opacity-0' disabled={!q} onClick={clearSearchInput} aria-label='Clear search'>
                    <FaTimes size={size} title="Clear" />
                </button>
            } 
            <button id='searchbtn' className='absolute left-full ml-2 top-1 p-2 bg-primary rounded w-fit disabled:opacity-50'  onClick={filterPosts} ref={searchBtn} disabled={!q} aria-label='Search archive'>
                <FaPlay size={size} title="Search" />
            </button>    
   
            <datalist id='tagsdata'>
                {tags && tags.map((tag, index) => (
                    <option value={tag} key={index}>{tag}</option>
                 ))}
            </datalist>

         </div>
    )
}

export default Search