import * as React from 'react'
import { Link } from 'gatsby'

import { FaPlay } from "@react-icons/all-files/fa/FaPlay";
import { FaFastForward } from "@react-icons/all-files/fa/FaFastForward";

const Pagination = ( { page, totalPages, tag }) => {

    let href = '/archive?p=',
    size = '40px'

    if (tag) href = `/archive?tag=${encodeURI(tag)}&p=`

    return (
        <>
        <div className='flex justify-center font-mono mb-8'>
            <Link to={href + 1} rel='noopener' className={"w-[3rem] flex justify-center items-center text-white text-mono p-2 m-1 bg-gray-100 hover:bg-gray-300 rounded bg-none hover:bg-none " + (1 === Number(page) ? 'active hover:bg-gray-100' : '')}>
                <FaFastForward size={size} title="First" className='rotate-180' />
            </Link>
            <Link to={href + (page - 1)} rel='noopener' className={"w-[3rem] flex justify-center items-center text-white text-mono p-2 m-1 bg-gray-100 hover:bg-gray-300 rounded bg-none hover:bg-none " + (1 === Number(page) ? 'active hover:bg-gray-100' : '')}>
                <FaPlay size={size} title="Previous" className='rotate-180' />
            </Link>
            <div className='flex items-center justify-center w-[4rem]'>
                {page}
            </div>
            <Link to={href + (Number(page) + 1)} rel='noopener' className={"w-[3rem] flex justify-center items-center text-white text-mono p-2 m-1 bg-gray-100 hover:bg-gray-300 rounded bg-none hover:bg-none " + (totalPages === Number(page) ? 'active hover:bg-gray-100 ' : '')}>
                <FaPlay size={size} title="Next" />
            </Link>
            <Link to={href + totalPages} rel='noopener' className={"w-[3rem] flex justify-center items-center text-white text-mono p-2 m-1 bg-gray-100 hover:bg-gray-300 rounded bg-none hover:bg-none " + (totalPages === Number(page) ? 'active hover:bg-gray-100' : '')}>
                <FaFastForward size={size} title="Last" />
            </Link>
        </div>
        <div className='text-center mb-8'>
            Page <strong>{page}</strong> of <strong>{totalPages}</strong> pages
            {tag && <div className='mt-8'>Filtering by <span className='uppercase font-mono'>{tag}</span></div>}
        </div>
        </>
    )
}

export default Pagination